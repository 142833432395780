.box {
    background: rgb(130,90,54);
    background: linear-gradient(90deg, rgba(130,90,54,1) 0%, rgba(231,187,80,1) 32%, rgba(238,224,197,1) 63%, rgba(189,144,51,1) 88%);
}

.logoBox {
    display: flex;
    gap: 1;
    cursor: pointer;
}

.logo {
    margin: auto;
    position: relative;
    width: 36px;
    height: 36px;
    margin-right: 0.5rem;
}

.logoText {
    font-size: 12px;
    color: white;
    align-items: end;
}

.navCenter {
    display: flex;
    gap: 1rem;
    font-size: 14px;

    @media (max-width:1024px) {
        display: none;
    }
}

.navRight {

    @media (max-width:1024px) {
        display: none;
    }
}

.navMobile {

    display: none;

    @media (max-width:1024px) {
        display: flex;
    }
}

.navMobileBg {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #893F8A;
    gap: 0.1rem;
    position: relative;
}

.navMobileList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
}