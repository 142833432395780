/* thai */
@font-face {
  font-family: '__Prompt_7f3792';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b4233f3b6619d75d-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Prompt_7f3792';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6e99940b73a95228-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Prompt_7f3792';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2d51cd5e580ca87f-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Prompt_7f3792';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b2e6e08dc7feb185-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Prompt_Fallback_7f3792';src: local("Arial");ascent-override: 95.84%;descent-override: 37.11%;line-gap-override: 0.00%;size-adjust: 113.73%
}.__className_7f3792 {font-family: '__Prompt_7f3792', '__Prompt_Fallback_7f3792';font-weight: 400;font-style: normal
}

.navigation_box__riIfN {
    background: rgb(130,90,54);
    background: linear-gradient(90deg, rgba(130,90,54,1) 0%, rgba(231,187,80,1) 32%, rgba(238,224,197,1) 63%, rgba(189,144,51,1) 88%);
}

.navigation_logoBox__fRxrZ {
    display: flex;
    gap: 1;
    cursor: pointer;
}

.navigation_logo__sJnWA {
    margin: auto;
    position: relative;
    width: 36px;
    height: 36px;
    margin-right: 0.5rem;
}

.navigation_logoText__pPTHL {
    font-size: 12px;
    color: white;
    align-items: end;
}

.navigation_navCenter__Fz9hi {
    display: flex;
    gap: 1rem;
    font-size: 14px;

    @media (max-width:1024px) {
        display: none;
    }
}

.navigation_navRight__031hK {

    @media (max-width:1024px) {
        display: none;
    }
}

.navigation_navMobile__Db6uw {

    display: none;

    @media (max-width:1024px) {
        display: flex;
    }
}

.navigation_navMobileBg__s26KT {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #893F8A;
    gap: 0.1rem;
    position: relative;
}

.navigation_navMobileList__6S_Lp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
}
/* thai */
@font-face {
  font-family: '__Prompt_6adc8a';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/c20485ff1d6a85fc-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Prompt_6adc8a';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/383f46aa10ddeca1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Prompt_6adc8a';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/71f7cc57d75b06da-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Prompt_6adc8a';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/48d884cc9f645eb3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Prompt_6adc8a';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b4233f3b6619d75d-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Prompt_6adc8a';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6e99940b73a95228-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Prompt_6adc8a';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2d51cd5e580ca87f-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Prompt_6adc8a';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b2e6e08dc7feb185-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Prompt_Fallback_6adc8a';src: local("Arial");ascent-override: 95.84%;descent-override: 37.11%;line-gap-override: 0.00%;size-adjust: 113.73%
}.__className_6adc8a {font-family: '__Prompt_6adc8a', '__Prompt_Fallback_6adc8a';font-style: normal
}

